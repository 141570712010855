@keyframes blink {
    0% { opacity: 0.2; }
    20% { opacity: 1; }
    100% { opacity: 0.2; }
  }
  
  .typing-dots span {
    display: inline-block;
    
    opacity: 0.2;
    animation: blink 1s infinite both;
  }
  
  .typing-dots span:nth-child(2) {
    animation-delay: 0.2s;
  }
  .typing-dots span:nth-child(3) {
    animation-delay: 0.4s;
  }
  html {
    scroll-behavior: smooth;
  }